import { CSSTransitionGroup } from "react-transition-group";
import { useState } from "react";
import "./App.css";

function App() {
	const [currentSelection, setCurrentSelection] = useState("none");
	const [pageToggle, setPageToggle] = useState(false);

	const renderNav = (list) => {
		let transitionDelay = 1000;
		return list.map((tree) => {
			transitionDelay += 200;
			return (
				<CSSTransitionGroup
					transitionName={`iconAnimation`}
					transitionAppear={true}
					transitionAppearTimeout={10000}
					transitionEnterTimeout={1500}
					transitionLeaveTimeout={300}
					key={tree}
				>
					<div
						className={`hover ${
							pageToggle && tree === currentSelection
								? "toggle"
								: ""
						}`}
						key={tree}
						style={{ transitionDelay: `${transitionDelay}ms` }}
						onClick={() => setPageToggle(!pageToggle)}
						onMouseEnter={() => setCurrentSelection(tree)}
						onMouseLeave={() =>
							pageToggle ? 0 : setCurrentSelection("none")
						}
					>
						<img
							className="icon"
							src={`./assets/${tree}.png`}
							alt={tree}
						/>
					</div>
				</CSSTransitionGroup>
			);
		});
	};

	const renderTitle = () => (
		<>
			<CSSTransitionGroup
				transitionName="circleAnimation"
				transitionAppear={true}
				transitionAppearTimeout={2000}
				transitionEnterTimeout={1500}
			>
				<div className="circle" />
			</CSSTransitionGroup>
			<CSSTransitionGroup
				transitionName="titleAnimation"
				transitionAppear={true}
				transitionAppearTimeout={1000}
				transitionEnterTimeout={1500}
			>
				<div className="title">
					<h1>The</h1>
					<h1>Overstory</h1>
				</div>
			</CSSTransitionGroup>
		</>
	);

	const renderTreeInfo = () => {
		let tree = "";
		let character = "";
		let treeInformation = "";
		let characterInformation = "";
		if (currentSelection === "chestnut") {
			tree = "Chestnut Tree";
			character = "Nicholas Hoel";
			treeInformation =
				"The chestnut tree is very durable with extremely strong wood and has been cultivated by humans for a long time, representing longevity. This tree also produces large numbers of fruits, representing abundance. Some also believe the chestnut tree represents progress and change.";
			characterInformation =
				"Nicholas Hoel is an artist whose family has been living on the family farm for generations. He has had an easy life, living off of the money that he’s inherited. The chestnut tree represents Nicholas because the chestnut tree symbolizes abundance, Nicholas’s family’s wealth, and longevity, how his family’s wealth has lasted generations. \nI believe that Powers is going to have Nicholas become less self-centered, and more involved in the activism of saving trees and preventing deforestation. ";
		} else if (currentSelection === "mulberry") {
			tree = "Mulberry Tree";
			character = "Mimi Ma";
			treeInformation =
				"The mulberry tree takes a while to produce fruits (around ten years) but when it does, it produces a lot at once. This is why the mulberry tree often serves as a symbol of patience. Ancient Greeks also associated this tree with Athena, the goddess of wisdom.";
			characterInformation =
				"Mimi Ma is the daughter of a Chinese immigrant. Following in her late father’s footsteps, she becomes an engineer. She is extremely hardworking and wise, which is why the Mulberry tree was chosen as the tree that represents Mimi. I believe Powers is setting Mimi up to find new meaning in life through the fight to preserve nature, as the tree that her father planted is not doing well, and fighting deforestation is one way she can honour her late father.";
		} else if (currentSelection === "maple") {
			tree = "Maple Tree";
			character = "Adam Appich";
			treeInformation =
				"Maple trees are often known for the leaves that they grow, which changes colours from season to season. This is why Maple trees often represent change, growth, and adaptability.";
			characterInformation =
				"Adam Appich has had a tough childhood, and has had to suffer from an abusive father, a mean mother, and bullies in school. As he grew older, he started to develop an interest in psychology. This interest drives him into applying to college to study Psychology. The Maple tree suits Adam because the tree represents growth, change, and adaptability, which are qualities that Adam is shown to possess. He was able to adapt to and overcome the challenges of an abusive home, and ultimately became successful enough to go to college to pursue his interests. I believe that Powers is setting Adam up to become a successful professor and researcher that will come up with solutions on how to prevent deforestation.";
		} else if (currentSelection === "fir") {
			tree = "Douglas Fir Tree";
			character = "Douglas Pavlicek";
			treeInformation =
				"Fir trees remain the same throughout the seasons, keeping their green needles. This is unlike other trees, which often shed their leaves as the weather gets colder. Fir trees are also able to thrive in a multitude of environments, from dry cold climates to moist hot climates. This is why the Fir trees often represent strength and endurance.";
      characterInformation = "Douglas is a veteran who was involved in the Stanford Prison Experiment. During the Vietnam war, his plane was shot down, and he was saved by a banyan tree. After the war, he travels the country, and his travels brings him to a forest which he sees is being cut down. The Fir tree is an accurate representation of Douglas because the Fir tree represents durability, strength, and endurance, which are qualities that Douglas has been shown to posses, through his experience of the Stanford prison experience and him being a veteran during the Vietnam war. I believe that Powers will have Douglas become a core activist fighting against deforestation, as his life was saved by a tree, and now he feels like the only way to pay it back is to save the trees.";
      } else if (currentSelection === "fig") {
			tree = "Fig Tree";
			character = "Neelay Mehta";
			treeInformation =
				"Fig trees often grow roots which are located in the upper region of the soil and spreading very wide. This is why Fig trees represent stability. Fig trees also produce fruits that are very high in sugar, containing almost 40% sugar. This is why this tree also represents productivity.";
      characterInformation = "Neelay Mehta is the son of immigrant parents, who immigrated from India. When he was young, his father and him spend lots of time together bonding over computers and programming. However, when he was in his teen years, he suffers from an accident that leaves him in a wheelchair for the rest of his life. He does not let this event ruin his life, and continues pursuing coding. He ends up going to Stanford for Computer Science. The Fig tree represents productivity, which is certainly a quality that Neelay has, as he is able to become a master coder even in spite of his disability. My prediction is that later on in the book, he creates software that brings attention to the issue of deforestation."
      } else if (currentSelection === "gingko") {
			tree = "Maidenhair Tree";
			character = "Olivia Vandergriff";
			treeInformation =
				"The Gingko or Maidenhair tree is one of the oldest species of trees known to us, and has no living relatives. Native to China, this tree has been used to represent hope and peace as early as the 5th century AD. It’s ability to survive this long also shows strength and longevity.";
      characterInformation = "Olivia is a free spirited college student in the last year of school, who accidentally electrocutes herself while she was high on drugs. My prediction for her character is that she will wake up from her accident, and her near death experience will drive her to find new meaning in life. Her newly found mission in life will be to protect the environment and nature. The Maidenhair tree is a good representation for her character because it represents strength and longevity, which is shown by her survival of the near death experience."
      }
		return (
			<CSSTransitionGroup
				transitionName="backgroundAnimation"
				transitionAppear={true}
				transitionAppearTimeout={1}
			>
				<div className={`character ${currentSelection}`}>
					<div className={"header"}>
						<CSSTransitionGroup
							transitionName="titleAnimation"
							transitionAppear={true}
							transitionAppearTimeout={1000}
						>
							<h1>{tree.toUpperCase()}</h1>
						</CSSTransitionGroup>
						<CSSTransitionGroup
							transitionName="circleAnimation"
							transitionAppear={true}
							transitionAppearTimeout={2000}
						>
							<h2>{character}</h2>
						</CSSTransitionGroup>
						<div className="information">
							<div className="characterInfo">
								<CSSTransitionGroup
									transitionName="imageAnimation"
									transitionAppear={true}
									transitionAppearTimeout={2000}
									transitionEnterTimeout={1500}
								>
									<div className="characterFormat">
										<img
											src={`./assets/${currentSelection}Person.png`}
											className="characterPicture"
											alt={tree}
										/>
										<h3>{characterInformation}</h3>
									</div>
								</CSSTransitionGroup>
							</div>
							<div className="treeInfo">
								<CSSTransitionGroup
									transitionName="imageAnimation"
									transitionAppear={true}
									transitionAppearTimeout={2000}
									transitionEnterTimeout={1500}
								>
									<img
										src={`./assets/${currentSelection}Tree.png`}
										className="tree"
										alt={tree}
									/>
									<h3>{treeInformation}</h3>
								</CSSTransitionGroup>
							</div>
						</div>
					</div>
				</div>
			</CSSTransitionGroup>
		);
	};

	return (
		<div className="App">
			{currentSelection === "none" ? renderTitle() : renderTreeInfo()}
			<div className="navigation">
				{renderNav([
					"chestnut",
					"mulberry",
					"maple",
					"fir",
					"fig",
					"gingko",
				])}
			</div>
		</div>
	);
}

export default App;
